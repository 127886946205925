<template>
	<div class="w-full h-screen flex justify-center items-center p-4 md:p-8 lg:p-16">
		<img src="./assets/m3ridian.png" class="w-full max-w-4xl" alt="m3ridian logo" />
	</div>
</template>

<script>

export default {
	name: 'App'
}
</script>

<style>
</style>